import Vue from 'vue'
import VueRouter from 'vue-router'

import BaseStructure from 'components/baseStructure/BaseStructure'
import { seachLog } from 'network/api'
import { dateContrast } from 'utils/utils.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/index'
  },
  // 首页
  {
    path: '/index',
    component: resolve => require(['views/index/Index'], resolve),
    meta: {
      title: '首页'
    }
  },
  // 跳转
  {
    path: '/localhost',
    component: resolve => require(['views/localhost/index'], resolve),
    meta: {
      title: '跳转'
    }
  },
  // 商城
  {
    path: '/shop',
    component: resolve => require(['views/shop/Index'], resolve),
    meta: {
      title: '商城'
    }
  },
  // 分站开通
  {
    path: '/join',
    component: resolve => require(['views/join/Join'], resolve),
    meta: {
      title: '分站开通'
    }
  },
  // 系统公告
  {
    path: '/notice',
    component: resolve => require(['views/notice/Notice'], resolve),
    meta: {
      title: '系统公告'
    }
  },
  // 体验分提升
  {
    path: '/dyScore',
    component: resolve => require(['views/dyScore/index'], resolve),
    meta: {
      title: '抖店提分'
    }
  },
  // 登录
  {
    path: '/login',
    component: resolve => require(['views/login/Login'], resolve),
    meta: {
      title: '登录'
    }
  },
  // 注册
  {
    path: '/register',
    component: resolve => require(['views/register/Register'], resolve),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/xieyi',
    component: resolve => require(['views/account/xieyi/xieyi'], resolve),
    meta: {
      title: '用户使用协议'
    }
  },
  // 重置密码
  {
    path: '/rePwd',
    component: resolve => require(['views/rePwd/RePwd'], resolve),
    meta: {
      title: '重置密码'
    }
  },
  // 会员中心
  {
    path: '/member',
    component: resolve => require(['views/member'], resolve),
    meta: {
      title: '会员中心'
    }
  },
  // 推广大使
  {
    path: '/promotion',
    component: resolve => require(['views/promotion'], resolve),
    meta: {
      title: '推广收益'
    }
  },
  // 快递价格
  {
    path: '/fastmail',
    component: resolve => require(['views/fastmail'], resolve),
    meta: {
      title: '快递价格'
    }
  },
  // 中间页
  {
    path: '/amongPage',
    component: resolve => require(['views/amongPage'], resolve),
    meta: {
      title: ''
    }
  },
  // 中间页
  {
    path: '/changePhone',
    component: resolve => require(['views/account/changePhone'], resolve),
    meta: {
      title: ''
    }
  },
  {
    path: '/saleOrder',
    component: resolve => require(['views/saleOrder/index'], resolve),
    meta: {
      title: ''
    }
  },
  // 订单管理
  {
    path: '/orderAdmin',
    redirect: '/orderAdmin/purchase',
    component: BaseStructure,
    beforeEnter (to, from, next) {
      if (window.localStorage.getItem('userInfo')) {
        next()
      } else {
        next('login')
      }
    },
    children: [
      {
        path: 'purchase',
        component: resolve => require(['views/orderAdmin/purchase/Purchase'], resolve),
        meta: {
          title: '礼品采购'
        }
      },
      {
        path: 'orderList',
        component: resolve => require(['views/orderAdmin/orderList/OrderList'], resolve),
        meta: {
          title: '订单列表'
        }
      },
      {
        path: 'packList',
        component: resolve => require(['views/orderAdmin/packList'], resolve),
        meta: {
          title: '包裹列表'
        }
      },
      {
        path: 'storeBind',
        component: resolve => require(['views/orderAdmin/storeBind/StoreBind'], resolve),
        meta: {
          title: '店铺绑定'
        }
      },
      {
        path: 'sendAddress',
        component: resolve => require(['views/orderAdmin/sendAddress/SendAddress'], resolve),
        meta: {
          title: '发件地址'
        }
      }
    ]
  },

  // 个人中心
  {
    path: '/account',
    redirect: '/account/base',
    component: BaseStructure,
    children: [
      {
        path: 'base',
        component: resolve => require(['views/account/base/Base'], resolve),
        meta: {
          title: '基础信息'
        }
      },
      {
        path: 'balance',
        component: resolve => require(['views/account/balance/Balance'], resolve),
        meta: {
          title: '余额管理'
        }
      },
      {
        path: 'inviteRewards',
        component: resolve => require(['views/account/inviteRewards/InviteRewards'], resolve),
        meta: {
          title: '邀请奖励'
        }
      },
      {
        path: 'inviteFriends',
        component: resolve => require(['views/account/inviteFriends/InviteFriends'], resolve),
        meta: {
          title: '邀请好友'
        }
      },
      {
        path: 'income',
        component: resolve => require(['views/account/income/income'], resolve),
        meta: {
          title: '收益明细'
        }
      },
      {
        path: 'tgIncome',
        component: resolve => require(['views/account/tgIncome/index'], resolve),
        meta: {
          title: '推广收益'
        }
      },
      {
        path: 'errorOrder',
        component: resolve => require(['views/account/errorOrder/index'], resolve),
        meta: {
          title: '异常订单'
        }
      }
    ]
  },

  // 工单中心
  {
    path: '/workOrder',
    redirect: '/workOrder/orderSumbit',
    component: BaseStructure,
    children: [
      {
        path: 'orderSumbit',
        component: resolve => require(['views/workOrder/orderSumbit/OrderSumbit'], resolve),
        meta: {
          title: '提交工单'
        }
      },
      {
        path: 'myOrder',
        component: resolve => require(['views/workOrder/myOrder/MyOrder'], resolve),
        meta: {
          title: '我的工单'
        }
      }
    ]
  },

  // 网站后台
  {
    path: '/oem',
    redirect: '/oem/manage',
    component: BaseStructure,
    children: [
      {
        path: 'manage',
        component: resolve => require(['views/oem/manage/Manage'], resolve),
        meta: {
          title: '网站管理'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
const routerListall = ['/index', '/register', '/rePwd', '/shop', '/fastmail', '/amongPage', '/xieyi']
router.beforeEach((to, from, next) => {
  const data = JSON.parse(localStorage.getItem('lpwUserInfo') ? localStorage.getItem('lpwUserInfo') : '{}')
  if (data && data.jointime) {
    if (dateContrast() === dateContrast(data.jointime)) {
      const remark = sessionStorage.getItem('clickRecharge') || ''
      seachLog({ urlType: to.path, remark: remark }).then(res => {
        sessionStorage.removeItem('clickRecharge')
      })
    }
  }
  next()
})
router.afterEach((to, from, next) => {
  if (!localStorage.getItem('lpwUserInfo') && !sessionStorage.getItem('lpwUserInfo') && routerListall.indexOf(to.path) === -1) {
    router.push('/login')
  }
})

export default router
