// 将数字强制保留两位小数
export function mustTwoDecimal (number) {
  var num = number.toString()
  var index = num.indexOf('.')
  if (index <= 0) {
    num += '.'
    index = num.length - 1
  }
  while ((index + 3) !== num.length) {
    num += '0'
  }
  return num
}

// 时间戳转化
export function timeConversion (time) {
  var now
  if (time) {
    now = new Date(time * 1000)
  } else {
    now = new Date()
  }
  var year = now.getFullYear()
  var month = now.getMonth() + 1
  var date = now.getDate()
  var hour = now.getHours()
  var minute = now.getMinutes()
  var second = now.getSeconds()
  if (time) {
    return year + '-' + month + '-' + date + '   ' + hour + ':' + minute + ':' + second
  } else {
    return year + '' + month + '' + date + '' + hour + '' + minute + '' + second
  }
  // return year + '-' + month + '-' + date + '   ' + hour + ':' + minute + ':' + second
}

export function dateContrast (time) {
  var now
  if (time) {
    now = new Date(time * 1000)
  } else {
    now = new Date()
  }
  var year = now.getFullYear()
  var month = now.getMonth() + 1
  var date = now.getDate()
  return year + '-' + month + '-' + date
}

// 手机号码中间隐藏函数
export function hideCenterPhone (phone) {
  if (!phone) return ''
  const phoneStart = phone.substring(0, 3)
  const phoneEnd = phone.substring(7, 11)
  return phoneStart + '****' + phoneEnd
}

// 名字开头隐藏函数
export function hideStartName (name) {
  if (!name) return ''
  const nameEnd = name.substring(1)
  return '*' + nameEnd
}
