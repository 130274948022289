import axios from 'axios' // 引入axios
// import store from '../store/actions'
import router from '../router/index'
import { Message } from 'element-ui' // 引入element-ui Message组件
import Vue from 'vue'
export function request (config) {
  // 创建axios实例
  const instance = axios.create({
    baseURL: window.location.host === 'hldf.haolidaifa.com' ? 'http://ht.haolidaifa.com' : 'http://scs.4723bao.com',
    timeout: 500000
  })
  // 请求拦截器
  instance.interceptors.request.use(
    config => {
      if (config.params) {
        config.params.key = Vue.prototype.$configList[window.location.host]
      }
      return config
    },
    err => {
      return err
    }
  )
  // 响应拦截器
  instance.interceptors.response.use(
    res => {
      return res.data
    },
    err => {
      console.log(err.message.indexOf('401'), 'errrrr')
      if (err.message.indexOf('401') !== -1) {
        // store.commit('logout')
        // router.replace({
        //   path: 'login',
        //   query: { redirect: router.currentRoute.fullPath }
        // })
        router.push('/login')
      } else {
        Message.error('网络错误，请稍后重试')
      }
      return err
    }
  )
  // 发送真正的网络请求
  return instance(config)
}
