<template>
  <div class="header">
    <div class="logo" @click="toIndex">
      <img :src="$store.getters.getSiteInfo.logal">
      <span>{{$store.getters.getSiteInfo.name}}</span>
    </div>
    <div class="menu">
      <div class="menuList" v-for="(item, index) in menuList" :key="index" @click="menuItemClick(item.url)" :class="{ 'active': indexPath == '/orderAdmin/purchase' && index == 1 }">
        <i class="menuTag new" v-if="item.url == '/promotion'">最新上线</i>
        {{item.name}}
      </div>
    </div>
    <div class="right">
      <div v-if="isMain" class="lianxi promotionHeader">
        <img v-if="groupName === '普通推广'" src="../../assets/images/lev1.png" style="width: 25px;height: 25px;" />
        <img v-if="groupName === '银牌推广'" src="../../assets/images/lev2.png" style="width: 25px;height: 25px;" />
        <img v-if="groupName === '金牌推广'" src="../../assets/images/lev3.png" style="width: 25px;height: 25px;" />
        <img v-if="groupName === '钻石推广'" src="../../assets/images/lev4.png" style="width: 25px;height: 25px;" />
        <span>{{groupName}}</span>
      </div>
      <div v-else class="lianxi" @mouseover="mouseOverKefu" @mouseleave="mouseLeaveKefu">
        <div class="box">
          <i class="el-icon-service icon"></i>
          <span>联系客服</span>
        </div>
        <div v-show="showKefu" class="show">
          <div @click="clickKefu">在线客服</div>
          <div @mouseover="mouseOverQR" @mouseleave="mouseLeaveQR">微信</div>
          <div v-show="showQR" class="qr">
            <img :src="$store.getters.getSiteInfo.wechat_service">
          </div>
        </div>
      </div>
      <div class="user" @mouseover="mouseOverUser" @mouseleave="mouseLeaveUser">
        <div class="box">
          <i class="el-icon-user icon"></i>
          <span>{{this.$store.getters.getUserInfo.username}}</span>
        </div>
        <div v-show="showUser" class="show">
          <div @click="toUser">个人中心</div>
          <div @click="logout">退出登陆</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      menuList: [
        { name: '首页', url: '/index' },
        { name: '礼品商城', url: '/orderAdmin/purchase' },
        { name: '会员中心', url: '/member' },
        { name: '快递价格', url: '/fastmail' },
        { name: '分站开通', url: '/join' },
        { name: '系统公告', url: '/notice' },
        { name: 'API接口', url: '/api' }
      ],
      notices: [
        '拼多多一键导入下单 2020-12-0378789999',
        '淘宝一键导入下单 2020-12-03 09:15:11',
        '京东一键导入下单 2020-12-03 09:15:11'
      ],
      showKefu: false,
      showUser: false,
      showQR: false,
      count: 0,
      groupName: this.$store.getters.getUserInfo.promotion_group_name || '普通推广'
    }
  },
  props: {
    indexPath: String
  },
  created () {
    if (this.isMain) {
      this.menuList = [
        { name: '首页', url: '/index' },
        { name: '礼品商城', url: '/orderAdmin/purchase' },
        { name: '会员中心', url: '/member' },
        { name: '推广大使', url: '/promotion' },
        { name: '快递价格', url: '/fastmail' },
        { name: '分站开通', url: '/join' },
        // { name: '抖店提分', url: '/dyScore' },
        { name: 'API接口', url: '/api' }
      ]
    }
  },
  methods: {
    toIndex () {
      this.$router.push('/index')
    },
    menuItemClick (url) {
      if (url === '/api') {
        window.open(this.$store.getters.getSiteInfo.api_service, '_blank')
      } else {
        this.$router.push(url)
      }
    },
    // 跳转到第三方链接
    goPage (url) {
      window.location.href = url
    },
    mouseOverKefu () {
      this.showKefu = true
    },
    mouseLeaveKefu () {
      this.showKefu = false
    },
    mouseOverUser () {
      this.showUser = true
    },
    mouseLeaveUser () {
      this.showUser = false
    },
    mouseOverQR () {
      this.showQR = true
    },
    mouseLeaveQR () {
      this.showQR = false
    },
    toUser () {
      this.$router.push('/account/base')
    },
    // 点击在线客服
    clickKefu () {
      window.open(this.$store.getters.getSiteInfo.online_service, '_blank')
    },
    // 退出登录
    logout () {
      this.$confirm('真的要注销登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 清楚用户信息,退出登录
        this.$store.dispatch('logout')
        this.$router.push('/index')
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped src="styles/components/adminHeader.css">
</style>
