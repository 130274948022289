import { LOGIN, LOGOUT, QUERYHEADER, GETSITEINFO } from './mutation-types'

export default {
  login ({ commit }, data) {
    commit(LOGIN, data)
  },
  logout ({ commit }) {
    sessionStorage.clear()
    commit(LOGOUT)
  },
  queryHeader ({ commit }, data) {
    commit(QUERYHEADER, data)
  },
  getSiteInfo ({ commit }, data) {
    commit(GETSITEINFO, data)
  }
}
