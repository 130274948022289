<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title + ' - ' + $store.getters.getSiteInfo.name"/>
  </div>
</template>

<script>
import { getSiteInfo } from 'network/api'

export default {
  created () {
    window.addEventListener('beforeunload', () => {
      sessionStorage.clear()
    })
    // 获取配置信息 并存入vuex
    getSiteInfo({ key: this.$configList[window.location.host] }).then(res => {
      if (res.code === 1) {
        this.$store.dispatch('getSiteInfo', res.data)
      }
    })
  }
}
</script>

<style>
</style>
