<template>
  <div class="page">
    <admin-header :indexPath="indexPath"/>
    <div class="content">
      <div>
        <side-menu @getIndexPath="getIndexPath"/>
      </div>
      <div class="main" ref="main">
        <router-view v-if="isMainRouterAlive">
          <app-main/>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import AdminHeader from 'components/baseStructure/AdminHeader'
import SideMenu from 'components/baseStructure/SideMenu'
import AppMain from './AppMain'

export default {
  components: {
    AdminHeader,
    SideMenu,
    AppMain
  },
  provide () {
    return {
      mainReload: this.mainReload
    }
  },
  data () {
    return {
      indexPath: '/orderAdmin/purchase',
      isMainRouterAlive: true
    }
  },
  methods: {
    getIndexPath (indexPath) {
      this.indexPath = indexPath
    },
    mainReload () {
      this.isMainRouterAlive = false
      this.$nextTick(function () {
        this.isMainRouterAlive = true
      })
    }
  }
}
</script>

<style scoped src="styles/components/baseStructure.css">
</style>
