import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VueWechatTitle from 'vue-wechat-title'
import VueClipboard from 'vue-clipboard2'

import 'element-ui/lib/theme-chalk/index.css'
import 'styles/base/base.css'
const configList = {
  'localhost:8080': '1459435534555418624',
  'www.daifaliwu.cn': '1459435534555418624',
  'f.haolidaifa.com': '521F777BD5A878834C7EE47CE1DC50C9',
  'hldf.haolidaifa.com': '1459435534555418624',
  'lp.hzjkwl.com': '1459435534555418624',
  'lp.daoyizx.top': '1459435534555418624',
  'lp2.daoyizx.top': '1459435534555418624',
  'df.daifaliwu.cn': '1459435534555418624',
  'hl.daifaliwu.cn': '1468399861413056512',
  'jydf.yililp.cn': '1480787803213271040',
  'www.zzpdd.com': '1493056896716902400',
  'abc.daifahaoli.com': '1497137682168549376',
  'xlp.daifahaoli.com': '1499935399613698048',
  'www.xiaolipinwang.cn': '1499935399613698048',
  'www.39lp.com': '1505012467045109760',
  '39lp.xichaosm.com': '1505012467045109760',
  '36lp.xichaosm.com': '1505012467045109760',
  'www.lclpdf.com': '1508352863330504704',
  'www.lpd99.com': '1511535288038592512',
  'www.h-yj.cn': '1512274465256509440',
  '151.daifaliwu.cn': '1512311440768372736',
  'chaosulipin.daifaliwu.cn': '1514429843784208384',
  'ffsy.daifaliwu.cn': '1515254933371359232',
  'qxdf.daifahaoli.com': '1517075880244023296',
  '168.lclpdf.com': '1523592541864333312',
  'hplp.yililp.cn': '1527473488917565440',
  'hg.lclpdf.com': '1529648395743006720',
  'www.douxueya.cn': '1529652227860467712',
  'www.168xlpw.com': '1535524269218467840',
  'www.xingelipin.com': '1536192599600271360',
  'www.168yifalipin.com': '1536555872635981824',
  'lp.xingelipin.com': '1536192599600271360',
  'lp.168yifalipin.com': '1536555872635981824',
  'lp.dayuncangdf.com': '1539470505789231104',
  'lp.168hlpdf.com': '1544525020347371520',
  'lp.168xlpw.com': '1535524269218467840',
  'xg.daifaliwu.cn': '1536192599600271360',
  'hj.dayuncangdf.com': '1544939127328346112',
  'www.haolping.com': '1544525020347371520',
  'hlp.168yifalipin.com': '1549990811163299840',
  'ddkd.lclpdf.com': '1551751438768672768',
  'yflp.3672yi.com': '1536555872635981824',
  'ddkd.3672yi.com': '1551751438768672768',
  'lp.luxidf.com': '1553979844621111296',
  'yclp.yililp.cn': '1555118508931682304',
  'wjlp.luxidf.com': '1556937991660900352',
  'lp.shjkk1323.com': '1459435534555418624',
  'lp.vipnmdf.com': '1564522069620822016',
  'lp.168lpdf.com': '1572823277661982720',
  'lp.lpd66.com': '1572823277661982720',
  'df.168lpdf.com': '1576541737185316864',
  'lp.daifalp.cn': '1577493671723536384',
  'lp.didilp.com': '1583708260005974016',
  'lp.lpd11.com': '1584378400963956736',
  'lp.xingxingdaifa.com': '1595982788727803904',
  'lp.huahuolpw.com': '1635107861543653376',
  'lp.jiuyaodaifa.cn': '1635155662583500800',
  'vip.shjkk1323.com': '1459435534555418624',
  'lp.lpyizhan.com': '1641621784740630528',
  'lp.feihudaifa.com': '1655066649713315840',
  'lp.weipindaifa.com': '1665986277738156032',
  'lp.ycdf168.com': '1686641199844298752',
  'rydf.lclpdf.com': '1716449288755744768',
  'lp.haojuwudaifa.com': '1751985974755332096',
  'lp.yihedf.com': '1776835467178807296',
  'df.lpyizhan.com': '1787721262915784704',
  'df.shjkk1323.com': '1459435534555418624'
}

Vue.use(VueWechatTitle)
Vue.config.productionTip = false
// Vue.prototype.$url = window.location.host === 'hldf.haolidaifa.com' ? 'http://ht.haolidaifa.com' : 'http://scs.4723bao.com'
Vue.prototype.$url = window.location.host === 'hldf.haolidaifa.com' ? 'http://ht.haolidaifa.com' : 'http://scs.4723bao.com'
Vue.prototype.$configList = configList
Vue.prototype.mywindow = window
Vue.prototype.isMain = configList[window.location.host] === '1459435534555418624'
Vue.use(ElementUI)
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
